










































import { meetingsStore } from '@/store'
import { Contact } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  components: {
    CreateBtn: () => import('@/components/Meetings/components/CreateBtn.vue'),
    TdDate: () => import('td-ui/dist/TdDate.vue'),
  },
})
export default class CalendarControlSection extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly date!: string

  @Getter private readonly profile!: Contact

  @Prop({
    type: Object,
  }) contact!: Contact

  private required = false
  private notRequired = false

  private get events () {
    const dates = meetingsStore.state.dates
    return dates.map(d => d.split(' ')[0].replaceAll('-', '/'))
  }

  private get canCreateMeeting () {
    return this.profile.canCreateMeeting
  }

  private changeSelectedDate (value: string) {
    if (!value) return
    this.$emit('changeCalendarDate', value)
  }

  private get dateType () {
    return !this.contact ? 'primary' : 'secondary'
  }
}
